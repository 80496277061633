<!-- 修改密码 -->
<template lang="">
  <div class="login">
    <div class="app-haeder">
      <van-nav-bar
        title="修改密码"
        left-text=""
        left-arrow
        class="navbar"
        @click-left="goBack"
      />
    </div>
    <div class="loginimg">
      <img
        src="https://lesson.iapeap.com/images/EmotionalValue/loginimg.png"
        alt=""
      />
    </div>

    <div class="from">
      <div class="from-title">修改密码</div>
      <div class="headline-fk"></div>

      <van-field
        v-model="oldPassWord"
        name="旧密码"
        label="旧密码"
        placeholder="旧密码"
        :rules="[{ required: true, message: '旧密码' }]"
      />
      <van-field
        v-model="newPassWord"
        type="password"
        name="新密码"
        label="新密码"
        placeholder="新密码"
        :rules="[{ required: true, message: '新密码' }]"
      />

      <div class="fromlogin">
        <button class="fromlogin-button" @click="onSubmit">修改密码</button>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { updatePassWord, memberLog } from "../../api/login";
export default {
  data() {
    return {
      oldPassWord: "",
      newPassWord: "",
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },

  methods: {
    onSubmit() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "正在修改密码",
        forbidClick: true,
      });
      let data = {
        userId: window.localStorage.getItem("userId"),
        oldPassWord: this.oldPassWord,
        newPassWord: this.newPassWord,
      };
      updatePassWord(data)
        .then((res) => {
          Toast.clear();

          if (res.msg == "原密码错误！") {
            Toast("原密码错误！");
          } else {
            Toast.success("修改成功");
            this.goBack();
          }
        })
        .catch((error) => {
          Toast.clear();
          Toast("服务器异常");
          console.error(error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "修改密码",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>
<style lang="less">
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.login {
  .loginimg {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 131px;
      height: 94px;
    }
  }

  .from {
    height: 428px;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    margin: 0 20px;
    box-shadow: 5px #333;
    position: relative;
    bottom: 4px;
    padding: 35px 30px 72px 30px;
    box-sizing: border-box;
  }

  .from-title {
    color: rgba(0, 0, 37, 1);
    font-size: 24px;
    line-height: 33px;
    position: relative;
    z-index: 1;
  }

  .from-title2 {
    color: rgba(0, 0, 37, 1);
    font-size: 24px;
    line-height: 33px;
    margin-top: -4px;
    margin-bottom: 50px;
  }

  .headline-fk {
    width: 64px;
    height: 5px;
    background: rgb(104, 154, 217);
    border-radius: 1px;
    position: relative;
    right: 5px;
    bottom: 9px;
    z-index: 0;
    transform: skew(-25deg);
  }

  .from-button {
    background-color: #fff;
    border: 0;
    color: rgb(114, 172, 244);
  }

  .van-cell {
    padding: 0.1rem 0rem;
    border: 0;
  }

  .van-field__body {
    border-radius: 8px;
    height: 28px;
    border: 1px solid rgba(224, 224, 224, 1);
    height: 28px;
  }

  .van-field__control {
    width: 130px;
  }

  .fromlogin {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    .fromlogin-button {
      background-color: rgba(114, 172, 244, 1);
      border-radius: 100px;
      padding: 5px 73px 6px 73px;
      border: 0;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
    }
  }

  .van-field__label {
    width: 45px;
    line-height: 32px;
  }

  .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-width: 0;
  }

  .van-cell::after {
    border-bottom: 0;
  }
}
</style>
